html {
  height: 100vh;
}

body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg {
  zIndex: 0;
  width: 100%;
  background: linear-gradient(0.375turn, #efefef, #cdd3e5); /*linear-gradient(0.375turn, #003c78, #001440); linear-gradient(0.375turn, #0080c8, #002040); linear-gradient(0.375turn, #e2e6eb, #b0c0d0); */
  height: 120%;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
